<p-dialog
  *ngIf="vm$ | async as vm"
  styleClass="picker-dialog"
  [(visible)]="visible"
  [modal]="true"
  [dismissableMask]="true"
  [draggable]="false"
  [resizable]="false"
  appendTo="body"
  [header]="header"
  [style]="{
    minHeight: '528px',
    height: '80vh',
    width: '50vw'
  }"
  [breakpoints]="{ '768px': '80vw', '576px': '100vw' }"
  (onHide)="onHide()"
>
  <div class="d-flex w-100 my-1 p-inputgroup">
    <span class="p-inputgroup-addon">
      <i class="fa-solid fa-magnifying-glass"></i>
    </span>
    <input
      #inputFilter
      pInputText
      type="text"
      class="w-100"
      [(ngModel)]="search"
      (input)="inputSearch()"
    />
    <ng-container *ngIf="isMultiselect">
      <span class="p-inputgroup-addon flex-shrink-0">
        <p-checkbox
          [binary]="true"
          [label]="'picker.includeAll' | translate"
          labelStyleClass="text-nowrap"
          [(ngModel)]="allSelected"
          (onChange)="selectAll($event.checked)"
        ></p-checkbox>
      </span>
      <button
        *ngIf="type === 'recipe'"
        pButton
        pRipple
        icon="fa-solid fa-rotate-left"
        class="flex-shrink-0"
        [pTooltip]="'reset' | translate"
        (click)="reset()"
      ></button>
    </ng-container>
  </div>
  <p-tabView *ngIf="visible" [(activeIndex)]="activeIndex" [scrollable]="true">
    <ng-container *ngFor="let categoryId of categoryIds; let i = index">
      <p-tabPanel
        *ngIf="categoryEntities[categoryId] as category"
        [selected]="i === activeIndex"
      >
        <ng-template pTemplate="header">
          <i [class]="categoryId | iconSmClass: 'category'"></i>
          <span
            class="d-none d-lg-inline ms-2 ps-1 text-nowrap text-truncate"
            >{{ category.name }}</span
          >
        </ng-template>
        <ng-template pTemplate="content">
          <p-scrollPanel
            [style]="{ height: 'calc(80vh - 165px)', minHeight: '363px' }"
          >
            <div
              *ngFor="let ids of categoryRows[categoryId]"
              class="d-flex flex-wrap"
            >
              <button
                *ngFor="let id of ids"
                pButton
                pRipple
                type="button"
                class="me-1 mb-1"
                [class.p-button-text]="id !== selection"
                [class.hover-action]="isMultiselect"
                [class.hover-active]="selection && selection.indexOf(id) !== -1"
                [icon]="id | iconSmClass: type"
                [pTooltip]="tooltip"
                tooltipPosition="bottom"
                [autoHide]="false"
                (click)="clickId(id)"
              >
                <i
                  *ngIf="isMultiselect"
                  class="hover-icon fa-solid fa-eye-slash"
                ></i>
                <ng-template #tooltip>
                  <lab-tooltip [id]="id" [type]="type"></lab-tooltip>
                </ng-template>
              </button>
            </div>
          </p-scrollPanel>
        </ng-template>
      </p-tabPanel>
    </ng-container>
  </p-tabView>
</p-dialog>
