<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>
<lab-columns></lab-columns>
<lab-costs></lab-costs>

<ng-template #translateSelectedItem pTemplate="selectedItem" let-item>
  {{ item.label | translate }}
</ng-template>
<ng-template #translateItem pTemplate="item" let-item>
  {{ item.label | translate }}
</ng-template>
