<ng-container *ngIf="vm$ | async as vm">
  <button
    *ngIf="vm.settingsXlHidden"
    pButton
    pRipple
    type="button"
    class="d-inline-flex d-none d-xl-flex p-button-lg p-button-text settings-button me-3"
    icon="fa-solid fa-bars"
    [label]="'settings.header' | translate"
    (click)="contentSvc.toggleSettingsXl()"
  ></button
  ><button
    pButton
    pRipple
    type="button"
    class="d-inline-flex d-xl-none p-button-lg p-button-text settings-button"
    icon="fa-solid fa-bars"
    [label]="'settings.header' | translate"
    (click)="contentSvc.toggleSettings()"
  ></button>
  <div class="d-none d-sm-flex p-inputgroup w-auto">
    <a [routerLink]="vm.gameInfo.route">
      <button
        type="button"
        pButton
        pRipple
        class="p-button-lg p-button-text icon-btn-lg"
        [icon]="vm.gameInfo.icon | iconSmClass"
        [label]="vm.gameInfo.label | translate"
        [pTooltip]="'app.resetTooltip' | translate"
        tooltipPosition="bottom"
      ></button>
    </a>
    <ng-container *ngFor="let game of vm.gameOptions">
      <a [routerLink]="game.routerLink">
        <button
          type="button"
          pButton
          pRipple
          class="p-button-lg p-button-text"
          [icon]="game.icon | iconSmClass"
          [pTooltip]="game.label ?? ''"
          tooltipPosition="bottom"
        ></button>
      </a>
    </ng-container>
  </div>
  <a class="d-sm-none" [routerLink]="vm.gameInfo.route">
    <p-splitButton
      styleClass="p-button-lg p-button-text"
      [icon]="vm.gameInfo.icon | iconSmClass"
      [model]="vm.gameOptions"
      (onDropdownClick)="cancelRouterLink($event)"
    >
    </p-splitButton
  ></a>

  <ul class="header-menu mb-1 mb-xl-0 px-0 px-sm-4 px-xl-0">
    <li *ngFor="let link of links">
      <a class="w-100" target="_blank" [href]="link.href">
        <button
          pButton
          pRipple
          type="button"
          class="p-button-text p-button-secondary p-button-sm d-flex justify-content-center w-100"
          [icon]="link.icon"
          [label]="link.label"
        ></button>
      </a>
    </li>
  </ul>
</ng-container>
