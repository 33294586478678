<span class="p-inputnumber p-component p-inputnumber-buttons-stacked">
  <input
    #input
    pInputText
    [id]="inputId"
    type="text"
    class="text-end"
    [class.hidden-buttons]="hideButtons"
    [style.width]="width"
    [ngModel]="value"
    labValidateNumber
    [minimum]="min"
    [maximum]="max"
    (input)="changeValue(input.value, 'input')"
    (blur)="changeValue(input.value, 'blur')"
    (keydown.enter)="changeValue(input.value, 'enter')"
  />
  <span *ngIf="!hideButtons" class="p-inputnumber-button-group">
    <button
      pButton
      pRipple
      type="button"
      class="p-button-outlined p-inputnumber-button p-inputnumber-button-up"
      [class.p-button-text]="textButtons"
      icon="fa-solid fa-angle-up"
      [disabled]="isMaximum"
      (click)="increase()"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      class="p-button-outlined p-inputnumber-button p-inputnumber-button-down"
      [class.p-button-text]="textButtons"
      icon="fa-solid fa-angle-down"
      [disabled]="isMinimum"
      (click)="decrease()"
    ></button>
  </span>
</span>
