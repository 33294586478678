export enum RecipeField {
  Machine = 0,
  Fuel = 1,
  MachineModules = 2,
  BeaconCount = 3,
  Beacon = 4,
  BeaconModules = 5,
  BeaconTotal = 6,
  Overclock = 7,
}
